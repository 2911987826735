/* eslint-disable no-undef,no-console, camelcase */
import { toast } from 'react-toastify';

import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import {
  GetMonthlyRewardPoints,
  GetActivityOverview,
  GetWorkoutVideo,
  GetConnectedDevices,
  ConnectOrRevokeDevice,
  GetRequestToken,
  GetListOfThingsToComplete,
  GetUserRewardPoints,
  GetActiveChallenge,
  GetMonthlyActivityOverview,
  UpdateDeviceNotificationReadStatus,
  GetUserRewardDetails,
  GetUserHistoryPoints,
  GetCompanyBrandingDetails,
  GetCompanyFeatures,
  GetCompanyRewardsDocuments, GetCompanyRewards,GetUpcomingChallenges,GetCompanyRewardTier
} from '../../constants/apiConstants';

import { STRAVA_TOKEN_URL, STRAVA_CLIENT_SECRET, STRAVA_CLIENT_ID } from '../../../utils/constants';
import moment from 'moment';
import { getActivityDate } from "../../../utils/methods";
import { getUser } from "../userProfileActions";
import { isNull } from "lodash";

const isLoading = () => (dispatch) => dispatch({ type: actionTypes.WELLNESS_DASHBOARD_LOADING });

const networkError = (error) => (dispatch) => dispatch({ type: actionTypes.NETWORK_ERROR, networkError: error });

const getActivityOverview = (startDate, endDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch(isLoading());
    try {
      const res = await http.get(`${GetActivityOverview}/${startDate}/${endDate}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_ACTIVITY_OVERVIEW, activityOverviewError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_ACTIVITY_OVERVIEW, activityOverview: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const updateDevices = (name, value) => (dispatch) => dispatch({ type: actionTypes.UPDATE_DEVICES, name, value });

const getListOfConnectedDevices = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetConnectedDevices, {}, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_LIST_OF_DEVICES, devicesError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_LIST_OF_DEVICES, devices: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const connectDevice = (deviceInfo) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(ConnectOrRevokeDevice, deviceInfo, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        localStorage.removeItem('TOKEN_SECRET');
        dispatch(getListOfThings());
        dispatch(updateDevices(deviceInfo.device, true));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const revokeDevice = (name) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  let deviceInfo = {};
  deviceInfo = name === 'fitbit' ? {
    "device": name,
    "action": "revoke",
    "platform": "web"
  } : {
    "device": name,
    "action": "revoke"
  };
  return async (dispatch) => {
    try {
      const res = await http.post(ConnectOrRevokeDevice, deviceInfo, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch(getUser());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fitbitIntegration = (authToken) => (dispatch) => {
  const deviceInfo = {
    "device": "fitbit",
    "action": "connect",
    "oauth_token": authToken,
    "platform": "web"
  };
  dispatch({ type: actionTypes.SAVE_FITBIT_TOKEN, fitbitToken: authToken });
  dispatch(connectDevice(deviceInfo));
};

const withingsIntegration = (authToken) => (dispatch) => {
  const deviceInfo = {
    "device": "withings",
    "action": "connect",
    "oauth_token": authToken,
    "platform": "web"
  };
  dispatch(connectDevice(deviceInfo));
};

const stravaIntegration = (authToken) => {
  let parameters = {
    client_id: STRAVA_CLIENT_ID,
    client_secret: STRAVA_CLIENT_SECRET,
    code: authToken
  };

  return (dispatch) => {
    try {
      const res = http.post(STRAVA_TOKEN_URL, parameters);
      res.then((response) => {
        const deviceInfo = {
          "device": "strava",
          "action": "connect",
          "oauth_token": response.data.access_token,
          "oauth_token_secret": STRAVA_CLIENT_SECRET
        };
        dispatch({ type: actionTypes.SAVE_STRAVA_TOKEN, stravaToken: response.data.access_token });
        dispatch(connectDevice(deviceInfo));
      });
    } catch (error) {
      dispatch(networkError(error.message));
      window.location.reload();
    }
  };
};

const getDailyWorkoutVideo = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetWorkoutVideo, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_DAILY_WORKOUT_VIDEO, workoutVideoError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_DAILY_WORKOUT_VIDEO, workoutVideo: res[0] });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getRequestToken = (name) => {
  const data = {
    "device": name
  };

  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(GetRequestToken, data, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch(updateDevices(name, false));
      } else {
        localStorage.setItem('TOKEN_SECRET', res.oauth_token_secret);
        window.location.href = res.url;
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getListOfThings = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch(isLoading());
    dispatch(getRewardPoints());
    try {
      const res = await http.get(GetListOfThingsToComplete, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_LIST_OF_THINGS, listError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_LIST_OF_THINGS, list: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getRewardPoints = () => {
  const AuthToken = localStorage?.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserRewardPoints, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_REWARD_POINTS, reward: res.rewards });
        let currentMonth = moment().startOf("month").format('MMMM');
        let currentYear = moment().startOf('year').format('YYYY');
        let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
        dispatch(fetchMonthlyRewardsPoint(obj.startDate));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const fetchMonthlyRewardsPoint = (startDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetMonthlyRewardPoints}/${startDate}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_MONTHLY_REWARD_POINTS, reward: res.reward_points });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const getActiveChallenge = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetActiveChallenge}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        // dispatch({type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message});
      } else {
        dispatch({ type: actionTypes.GET_ACTIVE_CHALLENGE, value: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};


const getUpComingChallenge = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUpcomingChallenges}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        // dispatch({type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message});
      } else {
        dispatch({ type: actionTypes.GET_UPCOMING_CHALLENGE, value: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getDailyActivityOverview = (startDate, endDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch(activityLoading());
    try {
      const res = await http.get(`${GetActivityOverview}/${startDate}/${endDate}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_ACTIVITY_OVERVIEW, activityOverviewError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_DAILY_ACTIVITY_OVERVIEW, dailyActivityOverview: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getMonthlyActivityOverview = (date) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch(activityLoading());
    try {
      const res = await http.get(`${GetMonthlyActivityOverview}/${date}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_MONTHLY_ACTIVITY_OVERVIEW, monthlyActivityOverview: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const activityLoading = () => (dispatch) => dispatch({ type: actionTypes.DAILY_ACTIVITY_LOADING });

const deviceNotificationReadStatus = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateDeviceNotificationReadStatus, '', { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getUserRewardDetails = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserRewardDetails, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_USER_REWARDS_DETAILS, userRewardsDetails: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserHistoryPoints = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserHistoryPoints, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_USER_HISTORY_POINTS, userHistoryPoints: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyBrandingDetails = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyBrandingDetails}/${isNull(id)?1:id}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        // toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_COMPANY_BRANDING_DETAILS, companyBrandingDetails: res });
      }
    } catch (error) {
      // toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getcompanyFeatures = (companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyFeatures}/${companyID}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_COMPANY_FEATURES, companyFeatures: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};


const getCompanyRewards = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetCompanyRewards, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        let companyPrograms = res.company_programs;
        companyPrograms = companyPrograms.map((data) => {
          data.program_levels = data.program_levels.map((level) => {
            level.end_date = moment(level.end_date).format('ll');
            level.achieved_date = level.achieved_date ? moment(level.achieved_date).format('MMM YYYY') : '';
            level.user_point = level.user_point ? level.user_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            level.point = level.point ? level.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            level.prize = level.prize ? level.prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            return level;
          });
          return data;
        });
        res.company_programs = companyPrograms;
        dispatch({ type: actionTypes.GET_COMPANY_REWARDS, companyRewards: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyRewardsDocuments = (documentId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyRewardsDocuments}/${documentId}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_COMPANY_DOCUMENTS, companyRewardsDocuments: res });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const clearCompanyRewardsDocuments = () => (dispatch) => dispatch({ type: actionTypes.CLEAR_COMPANY_DOCUMENTS });

const getCompanyRewardTierAPI = (companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyRewardTier}/${companyID}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.COMPANY_REWARDS_TIER, companyRewardsBarValues: res[0] });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getRewardPointsV2 = (userId, auth) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserRewardPoints}/${userId}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_REWARD_POINTS, reward: res.rewards });
        let currentMonth = moment().startOf("month").format('MMMM');
        let currentYear = moment().startOf('year').format('YYYY');
        let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
        dispatch(fetchMonthlyRewardsPointV2(obj.startDate, auth));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchMonthlyRewardsPointV2 = (startDate, auth) => {
  const AuthToken = auth;
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetMonthlyRewardPoints}/${startDate}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_ADMIN_REWARD_POINTS, rewardError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_ADMIN_MONTHLY_REWARD_POINTS, reward: res.reward_points });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getHistoryRewardPoints = (userId, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserRewardPoints}/${userId}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        dispatch({ type: actionTypes.FAILED_GET_REWARD_POINTS, rewardError: res.message });
      } else {
        dispatch({ type: actionTypes.GET_REWARD_HISTORY_POINTS, rewardData: {year:year,data:res.rewards} });
        // let currentMonth = moment().startOf("month").format('MMMM');
        // let currentYear = moment().startOf('year').format('YYYY');
        // let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
        // dispatch(fetchMonthlyRewardsPoint(obj.startDate));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserRewardHistoryDetails = (userId, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserRewardDetails}/${userId}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_USER_REWARD_HISTORY_DETAILS, userRewardHistoryDetails: {year:year,data:res} });
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

export {
  getActiveChallenge, fetchMonthlyRewardsPoint, getActivityOverview, fitbitIntegration, stravaIntegration, getDailyWorkoutVideo, getListOfConnectedDevices,
  revokeDevice, updateDevices, getRequestToken, connectDevice, getListOfThings, getRewardPoints, withingsIntegration, getDailyActivityOverview, getMonthlyActivityOverview,
  deviceNotificationReadStatus, getUserRewardDetails, getUserHistoryPoints, getCompanyBrandingDetails, getcompanyFeatures, getCompanyRewards, getCompanyRewardsDocuments, clearCompanyRewardsDocuments,getUpComingChallenge, getCompanyRewardTierAPI, getRewardPointsV2, getHistoryRewardPoints, getUserRewardHistoryDetails, fetchMonthlyRewardsPointV2
};
